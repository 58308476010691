import React, { useState, useEffect, useRef } from "react";
import "./LazyImage.css";

type Props = Partial<Pick<HTMLImageElement, "src" | "alt" | "className">>;

function LazyImage({ className, src, alt, ...rest }: Props) {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>();
  useEffect(() => {
    imgRef.current?.complete && setLoaded(true);
  }, []);

  return (
    <div style={{ backgroundColor: "#222", minHeight: 300 }}>
      <img
        {...rest}
        loading="lazy"
        src={src}
        alt={alt}
        // @ts-ignore
        ref={imgRef}
        onLoad={() => setLoaded(true)}
        className={`lazy-image ${className ? className : ""} ${
          loaded ? "loaded" : ""
        }`}
      />
    </div>
  );
}
export default LazyImage;
