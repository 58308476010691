import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "style";
import { FooterType } from "./Footer";

import styled from "styled-components";

const FooterLink = styled("a")`
  width: 100%;
  height: 100%;
  display: block;
  outline: none;
  padding: 2px;
  transition: box-shadow 1s cubic-bezier(0.2, 1, 0.3, 1);

  &:focus-visible {
    outline-style: none;
    border-color: transparent;
    box-shadow: ${colors.white} 0px 0px 0px 4px;
  }
`;

const FooterIcon = ({ url, icon }: FooterType) => (
  <li>
    <FooterLink href={url} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={icon} size="2x" color={colors.white} />
    </FooterLink>
  </li>
);

export default FooterIcon;
