import React from "react";
import {
  faGithub,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { FooterText } from "components";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import StyledIconWrappers from "./StyledIconWrappers";
import StyledFooter from "./StyledFooter";
import FooterRow from "./FooterRow";
import FooterIcon from "./FooterIcon";

export type FooterType = { url: string; icon: IconProp };

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <StyledFooter>
      <FooterRow>
        <FooterText>Copyright © Matt Lohmann {year}</FooterText>
        <StyledIconWrappers>
          <FooterIcon url="http://twitter.com/flashdevigner" icon={faTwitter} />
          <FooterIcon url="http://github.com/mlohmann" icon={faGithub} />
          <FooterIcon
            url="https://www.linkedin.com/in/matt-lohmann/"
            icon={faLinkedin}
          />
        </StyledIconWrappers>
      </FooterRow>
    </StyledFooter>
  );
};

export default Footer;
