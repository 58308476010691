import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "animate.css/animate.css";
import "./index.css";

ReactDOM.render(<App />, document.getElementById("root"));

console.log(
  "%cThanks for checking my site. Hit me up on Linkdin",
  "padding:30px;color:hsla(355, 78%, 56%, 1);background-color:black;font-size:60px;"
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
