// Main Palette
const $white = "hsla(355, 100%, 100%, 1)";
const $black = "hsla(0, 0%, 0%, 1);";
const $almostBlack = "#222";
const $imperialRed = "hsla(355, 78%, 56%, 1)";

// base colors
const $primary = $black;
const $secondary = $white;
const $tertiary = $imperialRed;

// background
const $backgroundPrimary = $black;
const $backgroundSecondary = $white;
const $backgroundTertiary = $imperialRed;

//  text
const $textPrimary = $white;
const $textSecondary = $black;
const $textTertiary = $black;
const $textHighlight = $imperialRed;

const colors = {
  black: $black,
  almostBlack: $almostBlack,
  white: $white,
  highlight: $imperialRed,
  //-----------------
  primary: $primary,
  secondary: $secondary,
  tertiary: $tertiary,
  //-----------------
  backgroundPrimary: $backgroundPrimary,
  backgroundSecondary: $backgroundSecondary,
  backgroundTertiary: $backgroundTertiary,
  //-----------------
  textPrimary: $textPrimary,
  textPrimaryHover: $textPrimary,
  textSecondary: $textSecondary,
  textTertiary: $textTertiary,
  textHighlight: $textHighlight,
};

export default colors;
