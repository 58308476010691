const insertNBSP = () => {
  const nbspSelectors = [
    "a",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "blockquote",
  ];

  const nbspWordMin = 6;

  nbspSelectors.forEach(function (selector) {
    const nbspElements = document.querySelectorAll(selector);
    nbspElements.forEach(function (element) {
      if (element.textContent) {
        const wordCount = element.textContent.split(" ").length;
        if (wordCount >= nbspWordMin) {
          element.innerHTML = element.textContent.replace(
            / ([^ ]*)$/,
            "&nbsp;$1"
          );
        }
      }
    });
  });
};

export { insertNBSP };
