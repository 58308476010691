import { sizes } from "style";
import { GridUnit } from "style/sizes";
import styled, { css } from "styled-components";

type ColumnType = {
  mobile?: number | string;
  tablet?: number | string;
  desktop?: number | string;
  largeDesktop?: number | string;
};

const getColumnWidth = (grid: GridUnit, count: number | string) => {
  const { columnWidth, gutter } = grid;
  const isSubcolumn = typeof count === "string";
  const split = isSubcolumn ? count.split("-") : [];
  const columnNum = isSubcolumn ? Number(split[0]) : count;

  if (columnNum === 0) {
    return "width: 0;";
  }

  const gutterNum = columnNum - 1;
  const width = columnWidth * columnNum + gutter * gutterNum;

  return `width: ${width}px;`;
};

const { grid } = sizes;

const Column = styled("div")<ColumnType>`
  ${({ desktop, largeDesktop }) => css`
    display: flex;
    flex-direction: column;

    ${sizes.query.mobile} {
      width: 100%;
    }

    ${sizes.query.tablet} {
      width: 100%;
    }
    ${sizes.query.desktop} {
      ${desktop && getColumnWidth(grid.desktop, desktop)};
    }

    ${sizes.query.largeDesktop} {
      ${largeDesktop && getColumnWidth(grid.largeDesktop, largeDesktop)};
    }
  `}
`;

const ColumnAlignEnd = styled(Column as any)`
  align-items: end;
`;
export { ColumnAlignEnd };
export default Column;
