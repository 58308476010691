import { sizes } from "style";
import styled from "styled-components";

const ShowMobile = styled("div")`
  ${sizes.toggle.showMobile}
`;

const HideMobile = styled("div")`
  ${sizes.toggle.hideMobile}
`;
const HideTouch = styled("div")`
  ${sizes.toggle.hideTouch}
`;
const ShowTouch = styled("div")`
  ${sizes.toggle.showTouch}
`;

const ShowDesktop = styled("div")`
  ${sizes.toggle.showDesktop}
`;

const HideDesktop = styled("div")`
  ${sizes.toggle.hideDesktop}
`;

export {
  ShowMobile,
  HideMobile,
  ShowTouch,
  HideTouch,
  ShowDesktop,
  HideDesktop,
};
